import React from 'react'
import PropTypes from 'prop-types'

import cccc from '../images/cccc.svg'

const clip = 'CLIP {+Soluciones}';

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <img src={cccc} alt="" width={'50px'} />
        </div>
        <div className="content">
            <div className="inner">
                <h1>{clip}</h1>
                <p style={{display : 'none'}}>A fully responsive site template designed by <a href="https://html5up.net">HTML5 UP</a> and released<br />
                for free under the <a href="https://html5up.net/license">Creative Commons</a> license.</p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('intro')}}>Nosotros</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('work')}}>Servicios</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>Acerca de</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Contacto</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
