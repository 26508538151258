import React from 'react'
import PropTypes from 'prop-types'
import Contact from './ContactForm'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

const clip = 'CLIP {+Soluciones}';

class Main extends React.Component {
  state = {
    name: '',
    email: '',
    message: ''
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }
  
  handleSubmit = event => {
    event.preventDefault()
    alert(`Welcome ${this.state.name} ${this.state.email}!`)
  }

  handleReset = event => {
    event.preventDefault()
    this.setState( {
      name: '',
      email: '',
      message: ''
    })
  }

  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Nosotros</h2>
          <span className="image main"><img src={pic01} alt="" /></span>
          <p>{clip} Es una empresa innovadora con más de 20 años de experiencia en desarrollos de sistemas e interfaces Web. Entre nuestros servicios se destacan además, consultoría y comunicación visual y organizacional, lo cual nos permite crear soluciones a su medida y a las de su sector, trabajando de manera integral la imagen y la identidad de su empresa.</p>
          <p>Contamos con un staff de profesionales capacitados para guiarlo en la búsqueda de los productos que satisfagan sus necesidades y optimicen sus recursos en las diferentes etapas de desarrollo de su organización.</p>
          <p>Dentro de un ágil y vertiginoso mercado empresarial, nos valemos de las últimas tecnologías y conocimientos para brindar las mejores opciones, diseñando e implementando planes y estrategias acorde a los objetivos de nuestros clientes. En los últimos años nos convertimos en una gran alternativa para facilitar las decisiones de su firma.</p>
          <p>Invierta su Tiempo en algo que lo gratifique. El resto...lo hacemos nosotros. </p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Servicios</h2>
          <span className="image main"><img src={pic02} alt="" /></span>
          <h3 className="major">Sistemas</h3>
          <p>Hoy, uno de los principales desafíos de una empresa es crear, administrar y mantener la información utilizando herramientas óptimas que provoquen tomas de decisiones correctas.</p>
          <p>En toda organización existen procedimientos, estos deben contemplar todos los pasos necesarios para lograr el objetivo final, el rendimiento comercial e industrial.</p>
          <p>Un sistema tiene el propósito de establecer los procedimientos y monitorizarlos, aplicando todas sus herramientas de manera que los objetivos se cumplan.</p>
          <p>Nuestro objetivo en {clip} es entregar una solución a las necesidades de su empresa, ofreciendo un alto grado de análisis en los procesos con la seguridad de ofrecer un diseño acorde a las exigencias, empleando tecnología innovadora en el desarrollo.</p>
          <p>Su misión es lograr los objetivos de la empresa; la nuestra, entregar las herramientas necesarias para que lo logre.</p>
          <h3 className="major">Consultoría</h3>
          <p>Muchas veces los objetivos planificados por una empresa no se cumplen en su totalidad y es dificil encontrar las causas con las herramientas que la organización posee.</p>
          <p>Nuestras intervenión profesional de Consultoría permiten identificar los nodos de conflicto en sus distintas etapas de desarrollo. Por tal motivo en el ámbito de la Comunicación Organizacional como en procedimientos tecnológicos la importancia de una visión estratégica y planificada puede asegurar que su empresa logre posicionarse entre las más competitivas del mercado.</p>
          <p>{clip} le ofrece la posibilidad de generar diferenciación, estableciendo pautas, estrategias y aplicando tecnología de información en los procesos, en los productos y en la comunicación.</p>
          {close}
          <h3 className="major">Diseño</h3>
          <p>Trabajar de manera planificada La Identidad Visual de su empresa es fundamental en el posicionamiento en la mente los clientes. Su imagen es la cara visible y el rasgo que la diferencia de los demás competidores del mercado. La coherencia y la continuidad en las piezas gráficas hacen a la organización planificada de la Comunicación visual una herramienta indispensable en el crecimiento de toda empresa.</p>
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Acerca de</h2>
          <span className="image main"><img src={pic03} alt="" /></span>
          <p>{clip} Es una empresa innovadora con más de 20 años de experiencia en desarrollos de sistemas e interfaces Web. Entre nuestros servicios se destacan además, consultoría y comunicación visual y organizacional, lo cual nos permite crear soluciones a su medida y a las de su sector, trabajando de manera integral la imagen y la identidad de su empresa.</p>
          <p>Contamos con un staff de profesionales capacitados para guiarlo en la búsqueda de los productos que satisfagan sus necesidades y optimicen sus recursos en las diferentes etapas de desarrollo de su organización.</p>
          <p>Dentro de un ágil y vertiginoso mercado empresarial, nos valemos de las últimas tecnologías y conocimientos para brindar las mejores opciones, diseñando e implementando planes y estrategias acorde a los objetivos de nuestros clientes. En los últimos años nos convertimos en una gran alternativa para facilitar las decisiones de su firma.</p>
          <p>Invierta su Tiempo en algo que lo gratifique. El resto...lo hacemos nosotros. </p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contacto</h2>
          <div>
            <Contact/>
          </div>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main