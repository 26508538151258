import React from 'react'
import { Formik } from 'formik';

export default class Contact extends React.Component {
    render() {
        return (
            <Formik 
                initialValues={{ name: '', email: '', message: '' }}
                validate={values => {
                        let errors = {};
                        // debugger;
                        if (!values.name) {
                            errors.name = 'Requerido';
                        } 
                        if (!values.email) {
                            errors.email = 'Requerido';
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = 'El correo no es válido';
                        }
                        if (!values.message) {
                            errors.message = 'Requerido';
                        } 
                        return errors;
                    }
                }
                onSubmit={(values, { setSubmitting }) => {
                    fetch('https://www.clipsolutions.com.ar/send_mail.php', {
                        method: 'POST',
                        headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(values)
                      })
                    setTimeout(() => {
                        setSubmitting(false);
                    }, 400);
                  }
                }>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className="field half first">
                                <label htmlFor="name">Nombre</label>
                                <input type="text" name="name" id="name" value={values.name} onChange={handleChange} onBlur={handleBlur}/>
                                {errors.name && touched.name && errors.name}
                            </div>
                            <div className="field half">
                                <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email" value={values.email} onChange={handleChange} onBlur={handleBlur}/>
                                {errors.email && touched.email && errors.email}
                            </div>
                            <div className="field">
                                <label htmlFor="message">Mensaje</label>
                                <textarea name="message" id="message" rows="4" value={values.message} onChange={handleChange} onBlur={handleBlur}></textarea>
                                {errors.message && touched.message && errors.message}
                            </div>
                            <ul className="actions">
                                <li><input type="submit" value="Enviar" className="special" disabled={isSubmitting} /></li>
                                <li><input type="reset" value="Limpiar" onClick={this.handleReset} /></li>
                            </ul>
                        </form>
                        <ul className="icons">
                            <li><a href="#" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
                            <li><a href={"mailto:info@clipsolutions.com.ar"} className="icon fa-envelope"><span className="label">info@clipsolutions.com.ar</span></a></li>
                        </ul>
                    </div>
                    )}
            </Formik>
      )}
}


//export default Contact;